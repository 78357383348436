import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Menu from "../components/menu"
import Footer from "../components/footer"

const LegalPage = ({location}) => (
    <Layout>
        
        <Menu location={location} />
            <h1 className="huge">Legal</h1>
            <p>
                Thanks for taking the time to learn about our
                legal&nbsp;policies. It's important stuff. If&nbsp;you have any
                questions about these policies, please{" "}
                <a
                    href="mailto:&#x73;&#x75;&#x70;&#x70;&#x6F;&#x72;&#x74;&#x40;&#x62;&#x63;&#x61;&#x73;&#x68;&#x34;&#x79;&#x6F;&#x75;&#x2E;&#x63;&#x6F;&#x6D;"
                    className="underline"
                    title="contact us"
                >
                    contact us
                </a>
                . We comply with the GDPR.
            </p>
            <div className="spacer line spacer-56">&nbsp;</div>
            <p className="link link-button">
                <a href="#terms" title="Terms and Conditions">
                    Terms and Conditions
                </a>
            </p>
            <p className="link link-button">
                <a href="#privacy" title="Privacy Policy">
                    Privacy Policy
                </a>
            </p>
            <p className="link link-button">
                <a href="#cookies" title="Cookies Policy">
                    Cookies Policy
                </a>
            </p>
            <p className="link link-button">
                <a href="#mail" title="Mail Policy">
                    Mail Policy
                </a>
            </p>
            <p className="link link-button">
                <a href="#2257" title="2257">
                    2257
                </a>
            </p>

            <div className="text">
                <div className="spacer spacer-56">&nbsp;</div>
                <a name="terms"></a>
                <div className="spacer spacer-40">&nbsp;</div>
                <h2 className="padding-bottom-0">Terms and Conditions</h2>
                <div className="spacer spacer-40">&nbsp;</div>

                <p className="small">
                    These Terms and Conditions ("Terms", "Terms and Conditions")
                    govern your relationship with bcash4you.com and
                    watch4beauty.com websites (the "Service") operated by
                    &#x4B;&nbsp;Production s.r.o. ("us", "we", or "our").
                </p>
                <p className="small">
                    Please read these Terms and Conditions carefully before
                    using the Service.
                </p>
                <p className="small">
                    Your access to and use of the Service is conditioned on your
                    acceptance of and compliance with these Terms. These Terms
                    apply to all visitors, users and others who access or use
                    the Service.
                </p>
                <p className="small padding-bottom-32">
                    By accessing or using the Service you agree to be bound by
                    these Terms. If you disagree with any part of the terms then
                    you may not access the Service.
                </p>

                <h3>Referral Program</h3>
                <p className="small padding-bottom-32">
                    Webmaster Referral Program is run through our authorized
                    sales agent CCBill. When you create/add an affiliate account
                    with CCBill, you will get an Affiliate ID connected to our
                    Service ("Affiliate ID") to access our Service. CCBill pays
                    commission of the earnings from every new signup, including
                    recurring sales, that you send to our Service. Please visit
                    ccbill.com.
                </p>

                <h3>Subscriptions</h3>
                <p className="small">
                    Some parts of the Service are billed on a subscription basis
                    ("Subscription(s)"). Subscribers are billed in advance on a
                    recurring and periodic basis ("Billing Cycle"). Billing
                    cycles are set either on a monthly or annual basis,
                    depending on the type of subscription plan subscriber select
                    when purchasing a Subscription.
                </p>
                <p className="small">
                    Subscriptions are operated by our authorized sales agents
                    CCBill and Epoch. Please visit ccbill.com and epoch.com.
                </p>
                <p className="small">
                    At the end of each Billing Cycle, Subscription will
                    automatically renew under the exact same conditions unless
                    subscriber cancel it or &#x4B;&nbsp;Production s.r.o.
                    cancels it. Subscriber may cancel Subscription renewal
                    either through CCBill or Epoch account management page or by
                    contacting &#x4B;&nbsp;Production s.r.o. customer support
                    team.
                </p>
                <p className="small padding-bottom-32">
                    A valid payment method, including credit card or PayPal, is
                    required to process the payment for Subscription. Subscriber
                    shall provide our authorized sales agents with accurate and
                    complete billing information including full name, address,
                    state, zip code, telephone number, and a valid payment
                    method information. By submitting such payment information,
                    subscriber automatically authorize our sales agents to
                    charge all Subscription fees incurred through subscriber's
                    account to any such payment instruments.
                    &#x4B;&nbsp;Production s.r.o. have no access to subscriber's
                    payment details.
                </p>

                <h3>Fee Changes</h3>
                <p className="small">
                    &#x4B;&nbsp;Production s.r.o., in its sole discretion and at
                    any time, may modify the Subscription fees for the
                    Subscriptions. Any Subscription fee change will become
                    effective at the end of the then-current Billing Cycle.
                </p>
                <p className="small">
                    &#x4B;&nbsp;Production s.r.o. will provide subscriber with a
                    reasonable prior notice of any change in Subscription fees
                    to give subscriber an opportunity to terminate his
                    Subscription before such change becomes effective.
                </p>
                <p className="small padding-bottom-32">
                    Subscriber's continued use of the Service after the
                    Subscription fee change comes into effect constitutes his
                    agreement to pay the modified Subscription fee amount.
                </p>

                <h3>Refunds</h3>
                <p className="small padding-bottom-32">
                    Certain refund requests for Subscriptions may be considered
                    by &#x4B;&nbsp;Production s.r.o. on a case-by-case basis and
                    granted in sole discretion of &#x4B;&nbsp;Production s.r.o..
                </p>

                <h3>Intellectual Property</h3>
                <p className="small">
                    W4B&reg; is a registered trademark of &#x4B;&nbsp;Production
                    s.r.o..
                </p>
                <p className="small">
                    The Service and its original content, features and
                    functionality are and will remain the exclusive property of
                    &#x4B;&nbsp;Production s.r.o. and its licensors. The Service
                    is protected by copyright, trademark, and other laws of both
                    the Czech Republic and foreign countries. Our trademarks and
                    trade dress may not be used in connection with any product
                    or service without the prior written consent of
                    &#x4B;&nbsp;Production s.r.o..
                </p>
                <p className="small padding-bottom-32">
                    You may use the promotional content offered on bcash4you.com
                    only to promote our Service. You may change the promotional
                    content in different ways, excluding copyright removal. If
                    you use the promotional content on your website, there
                    should be at least 1 visible and active affiliate link to
                    the Service on that website.
                </p>

                <h3>Links To Other Web Sites</h3>
                <p className="small">
                    Our Service may contain links to third-party web sites or
                    services that are not owned or controlled by
                    &#x4B;&nbsp;Production s.r.o..
                </p>
                <p className="small">
                    &#x4B;&nbsp;Production s.r.o. has no control over, and
                    assumes no responsibility for, the content, privacy
                    policies, or practices of any third party web sites or
                    services. You further acknowledge and agree that
                    &#x4B;&nbsp;Production s.r.o. shall not be responsible or
                    liable, directly or indirectly, for any damage or loss
                    caused or alleged to be caused by or in connection with use
                    of or reliance on any such content, goods or services
                    available on or through any such web sites or services.
                </p>
                <p className="small padding-bottom-32">
                    We strongly advise you to read the terms and conditions and
                    privacy policies of any third-party web sites or services
                    that you visit.
                </p>

                <h3>Termination</h3>
                <p className="small">
                    We may terminate or suspend your CCBill account and
                    Affiliate ID immediately, without prior notice or liability,
                    for any reason whatsoever, including without limitation if
                    you breach the Terms.
                </p>
                <p className="small padding-bottom-32">
                    Upon termination, your right to use the Service will
                    immediately cease. If you wish to terminate your account,
                    you may simply discontinue using the Service.
                </p>

                <h3>Limitation Of Liability</h3>
                <p className="small padding-bottom-32">
                    In no event shall &#x4B;&nbsp;Production s.r.o., nor its
                    directors, employees, partners, agents, suppliers, or
                    affiliates, be liable for any indirect, incidental, special,
                    consequential or punitive damages, including without
                    limitation, loss of profits, data, use, goodwill, or other
                    intangible losses, resulting from (i) your access to or use
                    of or inability to access or use the Service; (ii) any
                    conduct or content of any third party on the Service; (iii)
                    any content obtained from the Service; and (iv) unauthorized
                    access, use or alteration of your transmissions or content,
                    whether based on warranty, contract, tort (including
                    negligence) or any other legal theory, whether or not we
                    have been informed of the possibility of such damage, and
                    even if a remedy set forth herein is found to have failed of
                    its essential purpose.
                </p>

                <h3>Disclaimer</h3>
                <p className="small">
                    Your use of the Service is at your sole risk. The Service is
                    provided on an "AS IS" and "AS AVAILABLE" basis. The Service
                    is provided without warranties of any kind, whether express
                    or implied, including, but not limited to, implied
                    warranties of merchantability, fitness for a particular
                    purpose, non-infringement or course of performance.
                </p>
                <p className="small padding-bottom-32">
                    &#x4B;&nbsp;Production s.r.o. its subsidiaries, affiliates,
                    and its licensors do not warrant that a) the Service will
                    function uninterrupted, secure or available at any
                    particular time or location; b) any errors or defects will
                    be corrected; c) the Service is free of viruses or other
                    harmful components; or d) the results of using the Service
                    will meet your requirements.
                </p>

                <h3>Governing Law</h3>
                <p className="small">
                    These Terms shall be governed and construed in accordance
                    with the laws of Czech Republic, without regard to its
                    conflict of law provisions.
                </p>
                <p className="small padding-bottom-32">
                    Our failure to enforce any right or provision of these Terms
                    will not be considered a waiver of those rights. If any
                    provision of these Terms is held to be invalid or
                    unenforceable by a court, the remaining provisions of these
                    Terms will remain in effect. These Terms constitute the
                    entire agreement between us regarding our Service, and
                    supersede and replace any prior agreements we might have
                    between us regarding the Service.
                </p>

                <h3>Changes</h3>
                <p className="small">
                    We reserve the right, at our sole discretion, to modify or
                    replace these Terms at any time. If a revision is material
                    we will try to provide at least 30 days notice prior to any
                    new terms taking effect. What constitutes a material change
                    will be determined at our sole discretion.
                </p>
                <p className="small padding-bottom-32">
                    By continuing to access or use our Service after those
                    revisions become effective, you agree to be bound by the
                    revised terms. If you do not agree to the new terms, please
                    stop using the Service.
                </p>

                <p className="small padding-bottom-0">Effective date 2019.1.1</p>
            </div>

            <div className="spacer spacer-80 line">&nbsp;</div>

            <div className="text">
                <div className="spacer spacer-40">&nbsp;</div>
                <a name="privacy"></a>
                <div className="spacer spacer-40">&nbsp;</div>
                <h2 className="padding-bottom-0">Privacy Policy</h2>
                <div className="spacer spacer-40">&nbsp;</div>

                <p className="small">
                    &#x4B;&nbsp;Production s.r.o. ("us", "we", or "our")
                    operates bcash4you.com and watch4beauty.com websites (the
                    "Service").
                </p>
                <p className="small">
                    This page informs you of our policies regarding the
                    collection, use, and disclosure of personal data when you
                    use our Service and the choices you have associated with
                    that data.
                </p>
                <p className="small">
                    We use your data to provide and improve the Service. By
                    using the Service, you agree to the collection and use of
                    information in accordance with this policy. Unless otherwise
                    defined in this Privacy Policy, terms used in this Privacy
                    Policy have the same meanings as in our Terms and
                    Conditions, accessible from bcash4you.com
                </p>
                <p className="small padding-bottom-0">
                    We comply with the European General Data Protection
                    Regulation (GDPR).
                </p>
                <div className="spacer spacer-40">&nbsp;</div>

                <h3 className="padding-bottom-32">Definitions</h3>
                <h4 className="padding-bottom-8">Personal Data</h4>
                <p className="small padding-bottom-32">
                    Personal Data means data about a living individual who can
                    be identified from those data (or from those and other
                    information either in our possession or likely to come into
                    our possession).
                </p>
                <h4 className="padding-bottom-8">Usage Data</h4>
                <p className="small padding-bottom-32">
                    Usage Data is data collected automatically either generated
                    by the use of the Service or from the Service infrastructure
                    itself (for example, the duration of a page visit).
                </p>
                <h4 className="padding-bottom-8">Cookies</h4>
                <p className="small padding-bottom-32">
                    Cookies are small pieces of data stored on a User’s device.
                </p>
                <h4 className="padding-bottom-8">Data Controller</h4>
                <p className="small">
                    Data Controller means a person who (either alone or jointly
                    or in common with other persons) determines the purposes for
                    which and the manner in which any personal data are, or are
                    to be, processed.
                </p>
                <p className="small padding-bottom-32">
                    For the purpose of this Privacy Policy, we are a Data
                    Controller of your data.
                </p>
                <h4 className="padding-bottom-8">
                    Data Processor (or Service Providers)
                </h4>
                <p className="small">
                    Data Processor (or Service Provider) means any person (other
                    than an employee of the Data Controller) who processes the
                    data on behalf of the Data Controller.
                </p>
                <p className="small padding-bottom-32">
                    We may use the services of various Service Providers in
                    order to process your data more effectively.
                </p>
                <h4 className="padding-bottom-8">Data Subject</h4>
                <p className="small padding-bottom-32">
                    Data Subject is any living individual who is the subject of
                    Personal Data.
                </p>
                <h4 className="padding-bottom-8">User</h4>
                <p className="small padding-bottom-0">
                    The User is the individual using our Service. The User
                    corresponds to the Data Subject, who is the subject of
                    Personal Data.
                </p>
                <div className="spacer spacer-40">&nbsp;</div>

                <h3 className="padding-bottom-32">
                    Information Collection And Use
                </h3>
                <p className="small padding-bottom-32">
                    We collect several different types of information for
                    various purposes to provide and improve our Service to you.
                    Types of Data Collected:
                </p>
                <h4 className="padding-bottom-8">Personal Data</h4>
                <p className="small">
                    While using our Service, we may ask you to provide us with
                    certain personally identifiable information that can be used
                    to contact or identify you ("Personal Data"). Personally
                    identifiable information may include, but is not limited to:
                </p>
                <ul>
                    <li>
                        <p className="small padding-bottom-0">Email address</p>
                    </li>

                    <li>
                        <p className="small">Cookies and Usage Data</p>
                    </li>
                </ul>
                <p className="small padding-bottom-32">
                    We may use your Personal Data to contact you with
                    newsletters, marketing or promotional materials and other
                    information that may be of interest to you. You may opt out
                    of receiving any, or all, of these communications from us by
                    following the unsubscribe link or instructions provided in
                    any email we send or by contacting us.
                </p>
                <h4 className="padding-bottom-8">Usage Data</h4>
                <p className="small padding-bottom-32">
                    We may also collect information how the Service is accessed
                    and used ("Usage Data"). This Usage Data may include
                    information such as your computer's Internet Protocol
                    address (e.g. IP address), browser type, browser version,
                    the pages of our Service that you visit, the time and date
                    of your visit, the time spent on those pages, unique device
                    identifiers and other diagnostic data.
                </p>
                <h4 className="padding-bottom-8">Tracking &amp; Cookies Data</h4>
                <p className="small">
                    We use cookies and similar tracking technologies to track
                    the activity on our Service and hold certain information.
                </p>
                <p className="small">
                    Cookies are files with small amount of data which may
                    include an anonymous unique identifier. Cookies are sent to
                    your browser from a website and stored on your device.
                    Tracking technologies also used are beacons, tags, and
                    scripts to collect and track information and to improve and
                    analyze our Service.
                </p>
                <p className="small">
                    You can instruct your browser to refuse all cookies or to
                    indicate when a cookie is being sent. However, if you do not
                    accept cookies, you may not be able to use some portions of
                    our Service.
                </p>
                <p className="small">Examples of Cookies we use:</p>
                <ul>
                    <li>
                        <p className="small">
                            <span className="italic">Session Cookies.</span> We use
                            Session Cookies to operate our Service.
                        </p>
                    </li>
                    <li>
                        <p className="small">
                            <span className="italic">Preference Cookies.</span> We
                            use Preference Cookies to remember your preferences
                            and various settings.
                        </p>
                    </li>
                    <li>
                        <p className="small padding-bottom-32">
                            <span className="italic">Security Cookies.</span> We use
                            Security Cookies for security purposes.
                        </p>
                    </li>
                </ul>

                <h3>Use of Data</h3>
                <p className="small">
                    &#x4B;&nbsp;Production s.r.o. uses the collected data for
                    various purposes:
                </p>
                <ul>
                    <li>
                        <p className="small">To provide and maintain our Service</p>
                    </li>
                    <li>
                        <p className="small">
                            To notify you about changes to our Service
                        </p>
                    </li>
                    <li>
                        <p className="small">
                            To allow you to participate in interactive features
                            of our Service when you choose to do so
                        </p>
                    </li>
                    <li>
                        <p className="small">To provide customer support</p>
                    </li>
                    <li>
                        <p className="small">
                            To gather analysis or valuable information so that
                            we can improve our Service
                        </p>
                    </li>
                    <li>
                        <p className="small">To monitor the usage of our Service</p>
                    </li>
                    <li>
                        <p className="small">
                            To detect, prevent and address technical issues
                        </p>
                    </li>
                    <li>
                        <p className="small padding-bottom-32">
                            To provide you with news, special offers and general
                            information about other goods, services and events
                            which we offer that are similar to those that you
                            have already purchased or enquired about unless you
                            have opted not to receive such information
                        </p>
                    </li>
                </ul>

                <h3>Retention of Data</h3>
                <p className="small">
                    &#x4B;&nbsp;Production s.r.o. will retain your Personal Data
                    only for as long as is necessary for the purposes set out in
                    this Privacy Policy. We will retain and use your Personal
                    Data to the extent necessary to comply with our legal
                    obligations (for example, if we are required to retain your
                    data to comply with applicable laws), resolve disputes, and
                    enforce our legal agreements and policies.
                </p>
                <p className="small padding-bottom-32">
                    &#x4B;&nbsp;Production s.r.o. will also retain Usage Data
                    for internal analysis purposes. Usage Data is generally
                    retained for a shorter period of time, except when this data
                    is used to strengthen the security or to improve the
                    functionality of our Service, or we are legally obligated to
                    retain this data for longer time periods.
                </p>

                <h3>Transfer Of Data</h3>
                <p className="small">
                    Your information, including Personal Data, may be
                    transferred to — and maintained on — computers located
                    outside of your state, province, country or other
                    governmental jurisdiction where the data protection laws may
                    differ than those from your jurisdiction.
                </p>
                <p className="small">
                    If you are located outside Czech Republic and choose to
                    provide information to us, please note that we transfer the
                    data, including Personal Data, to Czech Republic and process
                    it there.
                </p>
                <p className="small">
                    Your consent to this Privacy Policy followed by your
                    submission of such information represents your agreement to
                    that transfer.
                </p>
                <p className="small padding-bottom-32">
                    &#x4B;&nbsp;Production s.r.o. will take all steps reasonably
                    necessary to ensure that your data is treated securely and
                    in accordance with this Privacy Policy and no transfer of
                    your Personal Data will take place to an organization or a
                    country unless there are adequate controls in place
                    including the security of your data and other personal
                    information.
                </p>

                <h3>Disclosure Of Data</h3>
                <p className="small">
                    &#x4B;&nbsp;Production s.r.o. may disclose your Personal
                    Data in the good faith belief that such action is necessary
                    to:
                </p>
                <ul>
                    <li>
                        <p className="small">To comply with a legal obligation</p>
                    </li>
                    <li>
                        <p className="small">
                            To protect and defend the rights or property of
                            &#x4B;&nbsp;Production s.r.o.
                        </p>
                    </li>
                    <li>
                        <p className="small">
                            To prevent or investigate possible wrongdoing in
                            connection with the Service
                        </p>
                    </li>
                    <li>
                        <p className="small">
                            To protect the personal safety of users of the
                            Service or the public
                        </p>
                    </li>
                    <li>
                        <p className="small padding-bottom-32">
                            To protect against legal liability
                        </p>
                    </li>
                </ul>

                <h3>Security Of Data</h3>
                <p className="small padding-bottom-32">
                    The security of your data is important to us, but remember
                    that no method of transmission over the Internet, or method
                    of electronic storage is 100% secure. While we strive to use
                    commercially acceptable means to protect your Personal Data,
                    we cannot guarantee its absolute security.
                </p>

                <h3>"Do Not Track" Signals</h3>
                <p className="small">
                    We do not support Do Not Track ("DNT"). Do Not Track is a
                    preference you can set in your web browser to inform
                    websites that you do not want to be tracked.
                </p>
                <p className="small padding-bottom-32">
                    You can enable or disable Do Not Track by visiting the
                    Preferences or Settings page of your web browser.
                </p>

                <h3>Your Rights</h3>
                <p className="small">
                    &#x4B;&nbsp;Production s.r.o. aims to take reasonable steps
                    to allow you to correct, amend, delete, or limit the use of
                    your Personal Data.
                </p>
                <p className="small">
                    Whenever made possible, you can update your Personal Data
                    directly within your account settings section. If you are
                    unable to change your Personal Data, please contact us to
                    make the required changes.
                </p>
                <p className="small">
                    If you wish to be informed what Personal Data we hold about
                    you and if you want it to be removed from our systems,
                    please contact us.
                </p>
                <p className="small">
                    In certain circumstances, you have the right:
                </p>
                <ul>
                    <li>
                        <p className="small">
                            To access and receive a copy of the Personal Data we
                            hold about you
                        </p>
                    </li>
                    <li>
                        <p className="small">
                            To rectify any Personal Data held about you that is
                            inaccurate
                        </p>
                    </li>
                    <li>
                        <p className="small">
                            To request the deletion of Personal Data held about
                            you
                        </p>
                    </li>
                </ul>
                <p className="small">
                    You have the right to data portability for the information
                    you provide to &#x4B;&nbsp;Production s.r.o.. You can
                    request to obtain a copy of your Personal Data in a commonly
                    used electronic format so that you can manage and move it.
                </p>
                <p className="small padding-bottom-32">
                    Please note that we may ask you to verify your identity
                    before responding to such requests.
                </p>

                <h3>Service Providers</h3>
                <p className="small">
                    We may employ third party companies and individuals to
                    facilitate our Service ("Service Providers"), to provide the
                    Service on our behalf, to perform Service-related services
                    or to assist us in analyzing how our Service is used.
                </p>
                <p className="small padding-bottom-32">
                    These third parties have access to your Personal Data only
                    to perform these tasks on our behalf and are obligated not
                    to disclose or use it for any other purpose.
                </p>
                <h4 className="padding-bottom-8">Analytics</h4>
                <p className="small">
                    We may use third-party Service Providers to monitor and
                    analyze the use of our Service.
                </p>
                <p className="small bold">Google Analytics</p>
                <ul>
                    <li>
                        <p className="small">
                            Google Analytics is a web analytics service offered
                            by Google that tracks and reports website traffic.
                            Google uses the data collected to track and monitor
                            the use of our Service. This data is shared with
                            other Google services. Google may use the collected
                            data to contextualize and personalize the ads of its
                            own advertising network.
                        </p>
                        <p className="small">
                            You can opt-out of having made your activity on the
                            Service available to Google Analytics by installing
                            the Google Analytics opt-out browser add-on. The
                            add-on prevents the Google Analytics JavaScript
                            (ga.js, analytics.js, and dc.js) from sharing
                            information with Google Analytics about visits
                            activity.
                        </p>
                        <p className="small padding-bottom-32">
                            For more information on the privacy practices of
                            Google, please visit the Google Privacy &amp; Terms
                            web page:{" "}
                            <a href="http://www.google.com/intl/en/policies/privacy/">
                                http://www.google.com/intl/...
                            </a>
                        </p>
                    </li>
                </ul>
                <h4 className="padding-bottom-8">Payments</h4>
                <p className="small">
                    We may provide paid products and/or services within the
                    Service. We may also provide Webmaster Referral Program
                    within the Service. In that case, we use third-party
                    services for payment processing (e.g. payment processors).
                </p>
                <p className="small">
                    We will not store or collect your payment details. That
                    information is provided directly to our third-party payment
                    processors whose use of your personal information is
                    governed by their Privacy Policy. These payment processors
                    adhere to the standards set by PCI-DSS as managed by the PCI
                    Security Standards Council, which is a joint effort of
                    brands like Visa, Mastercard, American Express and Discover.
                    PCI-DSS requirements help ensure the secure handling of
                    payment information.
                </p>
                <p className="small">The payment processors we work with are:</p>
                <p className="small bold">CCBill</p>
                <ul>
                    <li>
                        <p className="small">
                            Their Privacy Policy can be viewed at{" "}
                            <a href="https://www.ccbill.com/policies.php">
                                https://www.ccbill.com/...
                            </a>
                        </p>
                    </li>
                </ul>
                <p className="small bold">Epoch</p>
                <ul>
                    <li>
                        <p className="small padding-bottom-32">
                            Their Privacy Policy can be viewed at{" "}
                            <a href="https://epoch.com/privacy.html">
                                https://epoch.com/...
                            </a>
                        </p>
                    </li>
                </ul>

                <h3>Links To Other Sites</h3>
                <p className="small">
                    Our Service may contain links to other sites that are not
                    operated by us. If you click on a third party link, you will
                    be directed to that third party's site. We strongly advise
                    you to review the Privacy Policy of every site you visit.
                </p>
                <p className="small padding-bottom-32">
                    We have no control over and assume no responsibility for the
                    content, privacy policies or practices of any third party
                    sites or services.
                </p>

                <h3>Children's Privacy</h3>
                <p className="small">
                    Our Service does not address anyone under the age of 13
                    ("Children").
                </p>
                <p className="small padding-bottom-32">
                    We do not knowingly collect personally identifiable
                    information from anyone under the age of 13. If you are a
                    parent or guardian and you are aware that your Children has
                    provided us with Personal Data, please contact us. If we
                    become aware that we have collected Personal Data from
                    children without verification of parental consent, we take
                    steps to remove that information from our servers.
                </p>

                <h3>Changes To This Privacy Policy</h3>
                <p className="small">
                    We may update our Privacy Policy from time to time. We will
                    notify you of any changes by posting the new Privacy Policy
                    on this page.
                </p>
                <p className="small">
                    We will let you know via email and/or a prominent notice on
                    our Service, prior to the change becoming effective and
                    update the "effective date" at the top of this Privacy
                    Policy.
                </p>
                <p className="small padding-bottom-32">
                    You are advised to review this Privacy Policy periodically
                    for any changes. Changes to this Privacy Policy are
                    effective when they are posted on this page.
                </p>

                <p className="small padding-bottom-0">Effective date 2019.1.1</p>
            </div>

            <div className="spacer spacer-80 line">&nbsp;</div>

            <div className="text">
                <div className="spacer spacer-40">&nbsp;</div>
                <a name="cookies"></a>
                <div className="spacer spacer-40">&nbsp;</div>
                <h2 className="padding-bottom-0">Cookies Policy</h2>
                <div className="spacer spacer-40">&nbsp;</div>

                <p className="small">
                    &#x4B;&nbsp;Production s.r.o. ("us", "we", or "our") uses
                    cookies on bcash4you.com and watch4beauty.com websites (the
                    "Service"). By using the Service, you consent to the use of
                    cookies.
                </p>
                <p className="small padding-bottom-32">
                    Our Cookies Policy explains what cookies are, how we use
                    cookies, how third-parties we may partner with may use
                    cookies on the Service, your choices regarding cookies and
                    further information about cookies.
                </p>

                <h3>What are cookies</h3>
                <p className="small">
                    Cookies are small pieces of text sent to your web browser by
                    a website you visit. A cookie file is stored in your web
                    browser and allows the Service or a third-party to recognize
                    you and make your next visit easier and the Service more
                    useful to you.
                </p>
                <p className="small padding-bottom-32">
                    Cookies can be "persistent" or "session" cookies. Persistent
                    cookies remain on your personal computer or mobile device
                    when you go offline, while session cookies are deleted as
                    soon as you close your web browser.
                </p>

                <h3>How &#x4B;&nbsp;Production s.r.o. uses cookies</h3>
                <p className="small">
                    When you use and access the Service, we may place a number
                    of cookies files in your web browser.
                </p>
                <p className="small">We use cookies for the following purposes:</p>
                <ul>
                    <li>
                        <p className="small">
                            To enable certain functions of the Service
                        </p>
                    </li>
                    <li>
                        <p className="small">To provide analytics</p>
                    </li>
                    <li>
                        <p className="small">To store your preferences</p>
                    </li>
                </ul>

                <p className="small">
                    We use both session and persistent cookies on the Service
                    and we use different types of cookies to run the Service:
                </p>

                <ul>
                    <li>
                        <p className="small">
                            Essential cookies. We may use essential cookies to
                            authenticate users and prevent fraudulent use of
                            user accounts.
                        </p>
                    </li>
                    <li>
                        <p className="small">
                            Preferences cookies. We may use preferences cookies
                            to remember information that changes the way the
                            Service behaves or looks, such as the "remember me"
                            functionality of a registered user or a user's
                            language preference.
                        </p>
                    </li>
                    <li>
                        <p className="small padding-bottom-32">
                            Analytics cookies. We may use analytics cookies to
                            track information how the Service is used so that we
                            can make improvements. We may also use analytics
                            cookies to test new advertisements, pages, features
                            or new functionality of the Service to see how our
                            users react to them.
                        </p>
                    </li>
                </ul>

                <h3>Third-party cookies</h3>
                <p className="small padding-bottom-32">
                    In addition to our own cookies, we may also use various
                    third-parties cookies to report usage statistics of the
                    Service, deliver advertisements on and through the Service,
                    and so on.
                </p>

                <h3>What are your choices regarding cookies</h3>
                <p className="small">
                    If you'd like to delete cookies or instruct your web browser
                    to delete or refuse cookies, please visit the help pages of
                    your web browser.
                </p>
                <p className="small">
                    Please note, however, that if you delete cookies or refuse
                    to accept them, you might not be able to use all of the
                    features we offer, you may not be able to store your
                    preferences, and some of our pages might not display
                    properly.
                </p>
                <ul>
                    <li>
                        <p className="small">
                            For the Chrome web browser, please visit this page
                            from Google:{" "}
                            <a href="https://support.google.com/accounts/answer/32050">
                                https://support.google.com/...
                            </a>
                        </p>
                    </li>
                    <li>
                        <p className="small">
                            For the Internet Explorer web browser, please visit
                            this page from Microsoft:{" "}
                            <a href="http://support.microsoft.com/kb/278835">
                                http://support.microsoft.com/...
                            </a>
                        </p>
                    </li>
                    <li>
                        <p className="small">
                            For the Firefox web browser, please visit this page
                            from Mozilla:{" "}
                            <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">
                                https://support.mozilla.org/...
                            </a>
                        </p>
                    </li>
                    <li>
                        <p className="small">
                            For the Safari web browser, please visit this page
                            from Apple:{" "}
                            <a href="https://support.apple.com/kb/PH21411?locale=en_US">
                                https://support.apple.com/...
                            </a>
                        </p>
                    </li>
                    <li>
                        <p className="small padding-bottom-32">
                            For any other web browser, please visit your web
                            browser's official web pages.
                        </p>
                    </li>
                </ul>

                <h3>Where can you find more information about cookies</h3>
                <p className="small">
                    You can learn more about cookies and the following
                    third-party websites:
                </p>
                <ul>
                    <li>
                        <p className="small">
                            AllAboutCookies:{" "}
                            <a href="http://www.allaboutcookies.org/">
                                http://www.allaboutcookies.org
                            </a>
                        </p>
                    </li>
                    <li>
                        <p className="small padding-bottom-32">
                            Network Advertising Initiative:{" "}
                            <a href="http://www.networkadvertising.org/">
                                http://www.networkadvertising.org
                            </a>
                        </p>
                    </li>
                </ul>

                <p className="small padding-bottom-0">Effective date 2019.1.1</p>
            </div>

            <div className="spacer spacer-80 line">&nbsp;</div>

            <div className="text">
                <div className="spacer spacer-40">&nbsp;</div>
                <a name="mail"></a>
                <div className="spacer spacer-40">&nbsp;</div>
                <h2 className="padding-bottom-0">Mail Policy</h2>
                <div className="spacer spacer-40">&nbsp;</div>

                <p className="small">
                    &#x4B;&nbsp;Production s.r.o. ("us", "we", or "our")
                    operates bcash4you.com and watch4beauty.com websites (the
                    "Service").
                </p>
                <p className="small">
                    This statement informs you of our policies regarding the
                    collection and use of email address when you use our
                    Service.
                </p>
                <p className="small padding-bottom-32">
                    We take our Mail Policy very seriously. We know that
                    problems with spam are very serious and we want to take any
                    precaution to send only solicited mail.
                </p>

                <h3>Collecting and Communications</h3>
                <p className="small">
                    While using our Service, we may ask you to provide us with
                    certain personally identifiable information that can be used
                    to contact or identify you. Personally identifiable
                    information may include, but is not limited to, your email
                    address. By creating/adding an affiliate account with
                    CCBill, you agree that we may collect and use the email
                    address, you provided to CCBill during this process.
                </p>
                <p className="small padding-bottom-32">
                    We may use your email address to contact you with
                    newsletters, marketing or promotional materials and other
                    information that may be of interest to you. You may opt out
                    of receiving any, or all, of these communications from us by
                    following the unsubscribe link or instructions provided in
                    any email we send.
                </p>

                <h3>Sending Emails</h3>
                <p className="small">We promise the following:</p>
                <ul>
                    <li>
                        <p className="small">
                            We will never use third party services to send our
                            newsletters, we will do it on our own.
                        </p>
                    </li>
                    <li>
                        <p className="small">
                            We will never give or sell email addresses of our
                            subscribers to anyone.
                        </p>
                    </li>
                    <li>
                        <p className="small">
                            We will never send anything other than our
                            newsletters or special announcements (once a week).
                        </p>
                    </li>
                    <li>
                        <p className="small">
                            We will protect all email addresses gathered to not
                            let them leak.
                        </p>
                    </li>
                </ul>
                <p className="small">
                    We are taking these precautions of responsible bulk mail
                    sender for easy identification of our bulk mails and quick
                    response to misuse:
                </p>
                <ul>
                    <li>
                        <p className="small">Campaign headers X-Campaign-ID</p>
                    </li>
                    <li>
                        <p className="small">
                            Bulk identification headers Precedence: Bulk
                        </p>
                    </li>
                    <li>
                        <p className="small">
                            VERP headers (variable envelope return path) for
                            bounce processing
                        </p>
                    </li>
                    <li>
                        <p className="small">
                            Active sender policy framework (SPF) setup
                        </p>
                    </li>
                    <li>
                        <p className="small">
                            Registered response addresses for abuse reports at
                            the Network Abuse Clearinghouse
                        </p>
                    </li>
                    <li>
                        <p className="small padding-bottom-32">
                            Working and responsive support at
                            support@bcash4you.com
                        </p>
                    </li>
                </ul>

                <p className="small padding-bottom-0">Effective date 2019.1.1</p>
            </div>

            <div className="spacer spacer-80 line">&nbsp;</div>

            <div className="text">
                <div className="spacer spacer-40">&nbsp;</div>
                <a name="2257"></a>
                <div className="spacer spacer-40">&nbsp;</div>
                <h2 className="hyphens padding-bottom-0">18 U.S.C. &sect;2257</h2>
                <div className="spacer spacer-40">&nbsp;</div>

                <p className="small">
                    In compliance with the US Federal Labeling and
                    Record-Keeping Law (also known as 18 U.S.C. &sect;2257
                    Record Keeping Requirements Compliance Statement), all
                    models, actors, actresses and other persons that appear in
                    any visual depiction of actual sexually explicit conduct
                    appearing or otherwise contained in this website were over
                    the age of eighteen years at the time of the creation of
                    such depictions. All model's proof of age is held by the
                    Custodian of Records, which is listed below. All content and
                    images are in full compliance with the requirements of 18
                    U.S.C. &sect;2257 and associated regulations.
                </p>
                <p className="small padding-bottom-32">
                    All records required to comply with the Record keeping
                    requirements pursuant to 18 U.S.C. &sect;2257 are kept by
                    Custodian of Records Martin Kinkal, and are available for
                    inspection during normal business hours at the following
                    address: &#x4B;&nbsp;Production s.r.o., Tusarova 55, 170 00
                    Prague 7, Czech Republic.
                </p>

                <p className="small padding-bottom-0">Effective date 2019.1.1</p>
            </div>

            <div className="spacer spacer-80">&nbsp;</div>

            <Footer />
        
    </Layout>
)

export default LegalPage
